import { Component, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import ContentAPI from '@/api/requests/content'
import { contentType } from '@/config/content/contentStatus'

@Component
export default class FetchContentMixin extends Vue {
  private redirectError() {
    this.$router.replace('/error')
  }

  public async getSingleContent(type: string, pageId: number) {
    if (_.isNil(pageId) || _.isNaN(pageId)) {
      this.redirectError()
    }

    const fetchFunction =
      type === contentType.event
        ? ContentAPI.getSingleEvent
        : ContentAPI.getSingleNews
    const response = await fetchFunction(pageId)

    if (response.status !== 200) {
      this.redirectError()
    }

    return response.data
  }
}
